
    import { defineComponent } from 'vue';
    import Loader from '@/components/Loader.vue';
    import Breadcrumbs from '@/components/Breadcrumbs.vue';

    export default defineComponent({
        name: 'MediaTags',
        components: {
            Loader, Breadcrumbs,
        },

        data() {
            return {
                tags: [],
                loaded: false,
                breadcrumbs: [
                    {
                        name: 'Media',
                        url: 'media',
                    },
                    {
                        name: 'All Tags',
                        url: 'tags'
                    }
                ]
            }
        },

        async created() {
            await this.fetchTags();
        },

        methods: {
            async fetchTags() {
                this.loaded = false;

                const response = await fetch(`https://virtualwolf.org/rest/media/tags`);
                const data = await response.json();

                this.tags = data;
                this.loaded = true;

                document.title = `Media - All Tags | virtualwolf.xyz`
            }
        },
    })
